import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';

import TermsSection from '../components/CommonSections/TermsSection';

const PrivacyPage = () => (
  <>
    <SEO title="Sprive Surveys Terms and Conditions" />
    <TermsSection
      title="Surveys Terms and Conditions"
      content={(<SecondSection />)}
    />
  </>
);

const SecondSection = () => (
  <>
    <h2>General Terms and Conditions</h2>
    <h4>Acceptance of our terms</h4>
    <p>
      The Sprive panel is a service owned and created by Pureprofile on behalf of
      Sprive. For the purposes of this policy the Sprive panel will be referred to
      as 'The Community'.
    </p>
    <p>
      These Terms of Service (“terms”) govern your access to and use of The
      Community products, and services (“products”). Please read these Terms
      carefully; by accessing or using The Community products, you agree to be
      bound by the below Terms and by our Privacy Policy.
    </p>

    <br/>
    <h4>Registration</h4>
    <ol>
      <li>
        To access and use The Community, you must first login to your Sprive
        mobile app account. You’ll also be asked to accept these terms as part of
        the process, access to the The Community interface will only be granted
        once you accept these terms.
      </li>
      <li>
        You are entirely responsible for all activities and transactions made from
        your account. We are not liable for any loss or damage from any
        unauthorised use of your account.
      </li>
      <li>
        Notify us immediately of any unauthorised account use or any other
        security breach in your Sprive mobile app through our <Link to="/get-in-touch">Get In Touch</Link> page.
      </li>
      <li>
        You may only register a single profile in The Community via a single
        Sprive account. If you attempt to operate multiple accounts, we have the
        right to suspend or close any and all of these accounts without notice
        and to ban you from using The Community. In the event that we close your
        account(s) pursuant to this clause, you forfeit any rewards that you have
        accrued.
      </li>
    </ol>

    <br/>
    <h4>Your privacy</h4>
    <p>
      Privacy is an essential element of The Community. We are committed to
      protecting the privacy of all users. We use advanced technology and follow
      best practice standards to protect your privacy. For complete details on how
      we protect your privacy, consult our <Link to="/survey-privacy">Privacy Policy</Link>.
    </p>

    <br/>
    <h4>Your profile</h4>
    <ul>
      <li>
        When you enter into The Community, you’ll be given the opportunity to
        create an online profile. We will repeat these questions at set intervals
        to ensure your profile is always up to date. Conditional to this agreement
        and all other terms and conditions contained on the site, should you wish
        to update, change or delete profile information at any time you can
        contact us to take action.
      </li>
      <li>
        We use profile information to publish and deliver insights tailored to
        you, your interests and hobbies.
      </li>
      <li>
        We use profile information in conjunction with information obtained from
        other sources such as web cookies, your IP address and geolocation
        software to match and deliver relevant activities to you through The
        Community.
      </li>
      <li>
        We don’t disclose information provided by you while using The Community
        to any business in a format that may allow you to be personally
        identified. We may aggregate profile information with other members’
        profile information and allow businesses to access this grouped and
        de-identified information.
      </li>
    </ul>

    <br/>
    <h4>Insights</h4>
    <ol>
      <li>
        Insights are collected and de-identified data from members’ responses to
        profile questions. Insights may be launched or withdrawn without notice
        by us, on our products or other media like our social media platforms.
      </li>
      <li>
        You may share or publish the insights we publish, but only for
        non-commercial use. You need prior written consent to use insight for the
        following purposes:
        <ol type="a">
          <li>educational or research purposes; and</li>
          <li>commercial use.</li>
        </ol>
      </li>
      <li>
        When you share or publish an insight with a non-commercial purpose, in any
        media, you agree that:
        <ol type="a">
          <li>
            you will not misrepresent the information contained in the insight;
          </li>
          <li>
            you will not share and/or publish the insight in any way that would
            bring us, the site or other application into disrepute;
          </li>
          <li>
            you will not share and/or publish the insight on websites that:
            <ol type="I">
              <li>
                contain unlawful, threatening, abusive, defamatory, obscene,
                vulgar, pornographic, profane, indecent information or material;
                or
              </li>
              <li>
                promote, provide or offer unsuitable or inappropriate products or
                services, including but not limited to gambling, adult services
                and erotic products;
              </li>
            </ol>
          </li>
          <li>
            you will not share and/or publish the insight by using spam or
            spamming techniques.
          </li>
          <li>all intellectual property in the insight is owned by us.</li>
        </ol>
      </li>
      <li>
        To the extent the intellectual property in the insight vests in you, you
        agree that in consideration for us granting you a personal right to use
        the insight, you grant us license of the insight. This includes an
        irrevocable, perpetual, exclusive, fully-paid up, royalty-free, worldwide
        right and license, with the right to sublicense, use, reproduce, display,
        perform, create derivative works of, distribute and otherwise exploit that
        intellectual property in the insights.
      </li>
    </ol>

    <br/>
    <h4>Feed activities</h4>
    <p>
      You may receive invitations to various activities in The Community feed. You
      may either accept or skip them. If you accept an activity, you will be taken
      to a starting page. If you skip it, you will be shown the next available
      activity in your feed.
    </p>
    <p>
      Unless expressly indicated otherwise, the content of activities does not
      originate from us, and the inclusion of any particular content does not
      constitute a recommendation or endorsement of that content by us, our
      directors or employees.
    </p>
    <p>
      We make no express or implied guarantee or warranty about the nature,
      suitability or authenticity of activity content, or any offer contained in
      an activity.
    </p>
    <p>
      We make no express or implied guarantee or warranty about the number of
      activities you will be invited to participate in.
    </p>
    <p>
      Any offer contained in an activity is made by the business, and not
      Pureprofile. The business making the offer is solely responsible for both
      the accuracy of the offer and the fulfillment of any obligations attached to
      the offer.
    </p>
    <p>
      We are not liable for any damage or loss caused by activities or the
      acceptance of any offer contained in an activity.
    </p>

    <br/>
    <h4>Data Quality</h4>
    <p>
      To ensure The Community accesses high-quality data, we have put in place a
      number of quality control measures to ensure response data meets and exceeds
      industry standards and best practice.
    </p>
    <p>For each activity we conduct, the following data quality checks are verified:</p>
    <ul>
      <li>
        all open-ended answers are checked for:
        <ul>
          <li>offensive or inappropriate language; and</li>
          <li>incomprehensible free text responses such as ‘qwfqwfqwf’;</li>
        </ul>
      </li>
      <li>
        the time taken for you to complete the activity will be checked against the
        estimated completion time for that activity; and
      </li>
      <li>
        all responses will be checked for inconsistent or inaccurate answers, which
        can include (but are not limited to):
        <ul>
          <li>responses which contradict other responses you have submitted during an activity;</li>
          <li>responses which contradict information in your profile;</li>
          <li>responses which fail logic or trick testing within an activity;</li>
          <li>selecting all answers in a multi-response question to qualify for an activity; and</li>
          <li>
            for grid-style questions, selecting all answers in a column or row, or
            selecting answers in a diagonal pattern.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      You must not provide information in your profile that is, or responses in
      any activity that are:
    </p>
    <ul>
      <li>offensive or inappropriate;</li>
      <li>Incomprehensible;</li>
      <li>rushed or ill-thought out;</li>
      <li>inconsistent and/or inaccurate; or</li>
      <li>in a language other than the primary language of your country of registration.</li>
    </ul>
    <p>
      If you are found to have breached any of the terms of this section, we may:
    </p>
    <ul>
      <li>deduct any rewards you received for any related activity from your personal account balance;</li>
      <li>block you from receiving further activities;</li>
      <li>prevent you from using The Community, aspects of the site, or platform; and</li>
      <li>close your personal account.</li>
    </ul>

    <br/>
    <h4>Earning Rewards</h4>
    <p>
      You can earn money towards your mortgage by participating in The Community
      activities like surveys.
    </p>
    <p>To become entitled for a reward when participating in an activity:</p>
    <ul>
      <li>the information in your profile must match the targeting criteria for the activity;</li>
      <li>the end date for the activity must not have passed; and</li>
      <li>
        you must:
        <ol>
          <li>view and accept the invitation for the activity;</li>
          <li>answer all questions asked; and</li>
          <li>
            reach the final page at the conclusion of the activity and follow any
            instructions on that page.
          </li>
        </ol>
      </li>
    </ul>
    <p>
      Neither us nor a business will be liable for any loss or damage arising from
      you receiving an invitation or activity after the activity end date.
    </p>
    <p>
      If a business defaults on its obligation to fulfill an offer, we are not
      liable to fulfill the offer to you and are not liable for any loss or damage
      that results from the business not satisfying its reward offer.
    </p>
    <p>We store a record of any rewards you earn through interaction with The Community.</p>
    <p>
      We may debit or credit your account balance in The Community at any time and
      without notice, to rectify any credits made to, or debits from, your account
      balance which were caused by human error, technological error or any other
      mistake or systems failure.
    </p>
    <p>
      If you wish to dispute a reward, you should <Link to="/get-in-touch">contact us</Link> and describe the
      nature of the discrepancy. We will notify you of the results of any
      investigation and any determination made by us is final (without affecting
      any of your statutory rights).
    </p>

    <br/>
    <h4>Redeeming rewards</h4>
    <p>
      You are responsible for identifying any potential tax liabilities that may
      arise through your use of The Community. The Community is available to you
      as a consumer and not a business, trading organization or other entity
      subject to GST, VAT or sales tax.
    </p>

    <br/>
    <h4>Communications</h4>
    <p>
      We may contact you without offering a reward by making verifications of your
      account and profile information, for customer service issues and to confirm
      some personal account activity.
    </p>
    <p>
      We may also contact you on third party websites through targeted advertising,
      using the anonymized details you provide.
    </p>

    <br/>
    <h4>Your conduct</h4>
    <p>
      You must not use The Community for any purpose that is unlawful, or
      prohibited by this agreement or any other terms, conditions or notices
      appearing anywhere on The Community. Particularly, you must not:
    </p>
    <ul>
      <li>
        copy material that is made available on our platform by third parties and
        reproduce or publish it.
      </li>
      <li>
        use, post or transmit any:
        <ul>
          <li>
            unlawful, threatening, abusive, defamatory, obscene, vulgar,
            pornographic, profane or indecent information, language or material;
          </li>
          <li>
            communication that may either constitute or encourage conduct that is a
            criminal offense or may give rise to civil liability, or otherwise
            violate any applicable law;
          </li>
          <li>
            information or material which damages our reputation or the reputation
            of any business, or which we consider as likely to damage our
            reputation or the reputation of any business;
          </li>
          <li>
            material which violates or infringes the rights of any person, including
            but not limited to the rights of privacy, copyright, trademarks,
            patents, confidentiality and any other intellectual property, personal
            or proprietary rights;
          </li>
          <li>
            material of any kind which contains or elicits a computer or software
            corruption, virus or other harmful component; or
          </li>
          <li>
            communication that contains promotional or advertising material;
          </li>
        </ul>
      </li>
      <li>impersonate any person or entity;</li>
      <li>exploit any material on the network for a commercial purpose;</li>
      <li>use The Community, the site or platform to send spam; or</li>
      <li>
        use The Community, the site or platform to send to engage in any other
        forms of internet abuse, including:
        <ul>
          <li>
            tampering, hacking, spoofing or using robots or scripts; or
          </li>
          <li>
            copying, distributing, modifying or otherwise interfering with the
            administration, security or proper and intended function of any part of
            The Community.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      If you are found to have committed any of the above acts your personal
      account will be closed and you may be prosecuted for any damage, loss or
      expense directly or indirectly caused by your actions. While we are not
      obligated to supervise your use of The Community, we reserve the right to
      monitor the site and retain and disclose all information necessary to
      satisfy any applicable law, regulation, legal process or government request.
    </p>

    <br/>
    <h4>No offer of information, products or service</h4>
    <p>
      Unless expressly indicated, any offer for information, products or services
      you may receive through The Community or any activities is not an offer by
      us. We make no warranties or representations of any kind in relation to,
      and are not responsible for the accuracy, completeness, authenticity,
      reliability or suitability of any information contained in an activity or on
      The Community.
    </p>
    <p>
      It is the responsibility of the business offering the information, product
      or service to ensure its accuracy, completeness, authenticity, reliability
      or suitability. We are not liable for any loss or damage that results from
      your, or anybody else’s, reliance on the information, products or services
      provided or promoted by a business or any other person.
    </p>
    <p>
      By making information, products or services accessible through the
      platform, we are not soliciting any person to use such information,
      products or services in jurisdictions where this would be illegal.
    </p>

    <br/>
    <h4>No Warranties</h4>
    <p>
      Unless otherwise stated, the site and platform are provided on an ‘as-is’
      basis without any express or implied warranties or guarantees.
    </p>

    <br/>
    <h4>Liability and indemnity</h4>
    <p>
      You agree and accept that The Community, including our related bodies
      corporate, licensors, officers, directors and employees, are not liable for
      any loss or damage that results from:
    </p>
    <ul>
      <li>your interaction with The Community;</li>
      <li>
        your use of or access to, or any inability to use or access The Community;
      </li>
      <li>
        unauthorized access or alterations made to your communications,
        transmissions or profile information; or
      </li>
      <li>
        statements or conduct of any third party on The Community, the site,
        platform or through any application.
      </li>
    </ul>
    <p>
      You agree to indemnify us, our parents, subsidiaries, affiliated
      organizations and their directors, officers and employees, from any claim or
      demand, including reasonable legal fees, made by a third party, caused by
      or arising from your use of The Community, the platform, site or a violation
      of these Terms of Service by you.
    </p>

    <br/>
    <h4>General</h4>
    <p>
      All questions or disputes relating to The Community or any part of this
      agreement should be submitted to Sprive by contacting them at{' '}
      <a href="mailto:hello@sprive.com">hello@sprive.com</a>. We will endeavour
      to resolve any disputes expeditiously. All interpretations of the Terms of
      Service shall be at our discretion and any determination that we make in
      relation to an issue is final. In the event that we make an error, such
      error is not a breach of this agreement.
    </p>
    <p>
      If you’re found to have breached any part of this agreement, we may suspend
      and/or close your personal account. In the event of any such closure, the
      balance of rewards in your personal account at the time it is closed will be
      forfeited.
    </p>
    <p>
      If any part of this agreement becomes, or is found to be illegal, invalid
      or unenforceable, it will not affect the operation of the remainder of this
      agreement.
    </p>
    <p>
      Due to the constantly evolving nature of The Community and the site,
      platform, profile and activities, we reserve the right to add to, change or
      remove any part of the platform, site or this agreement at any time. These
      changes may be made without notification and you agree that it’s your
      responsibility to remain updated and aware of the terms of this agreement
      and other aspects of the platform and site.
    </p>
    <p>
      We have no control over the content on websites hyperlinked from the site,
      the platform or an application. We make no express or implied warranties in
      relation to the content of these sites, including the accuracy, completeness,
      reliability or suitability of the site for any particular purpose. This
      includes a hyperlink on the site, platform or in an application. We are not
      liable for any loss or damage that may result from your use of hyperlinks in
      the site, platform or applications or any loss or damage caused by your use
      or reliance on any website via a hyperlink.
    </p>
    <p>
      This agreement, access to and use of The Community, the site and platform
      and the terms of any disclaimer of warranties and liability are governed by
      the laws of New South Wales, Australia.
    </p>

  </>
);

export default PrivacyPage;
